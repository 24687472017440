import React, { useState, useEffect } from 'react'
import { View, Text, TouchableWithoutFeedback, StyleSheet, ScrollView , ActivityIndicator, TouchableOpacity} from 'react-native'
import { Conversation } from '../Conversation/Conversation.js'
import { useSelector, useDispatch } from "react-redux";
import { SecondaryColor, WhiteColor,  BlackColor, PrimaryColor, TextColor} from "../universalVariables"
import { GiftedChat } from 'react-native-gifted-chat';
import { getUserChatList, getMediaLink, scrollToEnd, supportAvatar,defaultAvatar , sortChatListByDateTime ,refreshAllowed,generateChatTicketFunc } from "../utils/helpers.js";
import ChatActionTypes from "../constants/chatConstants";
import api,{host} from "../utils/api.js";
import WebSocketClient from "@gamestdio/websocket";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft, faArrowRight, faSync} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';



export default function ConversationList() {
	const WAIT_INTERVAL = 3000;
	const completeChatList = useSelector(state => state.chatReducer.completeChatList); //Complete chat list from redux
	const language = useSelector(state => state.chatReducer.chat_language);
	const chat_ticket = useSelector(state => state.chatReducer.chat_ticket);
	const chat_loader = useSelector(state => state.chatReducer.chat_loader);
	const origin_chat_id = useSelector(state => state.chatReducer.origin_chat_id);
	const pagination_obj = useSelector(state => state.chatReducer.pagination_obj);
	const user_type = useSelector(state => state.chatReducer.user_type);
	const allowed_recipients = useSelector(state => state.chatReducer.allowed_recipients);
	const access_token = useSelector(state => state.chatReducer.access_token);
	const [messages, setMessages] = useState(null);
	const [dummy, setDummy] = useState(false);
	const [unreadMessageFrom, setUnreadMessageFrom] = useState([]);
	const [offlineUnreadApplied, setOfflineUnreadApplied] = useState(false);
	const [activeConversation, setActiveConversation] = useState(null);
	const [msgFrom, setmsgFrom] = useState({});
	const [activeUserTyping, setActiveUserTyping] = useState(null);
	const [typingTimeout, setTypingTimeout] = useState(null);
	const [ticketRequested, setTicketRequested] = useState(false);
	const [giftedChatRef] = React.useState(React.createRef());
	const [listViewRef] = useState(React.createRef());
	const isRTL = language === "ar" ? true : false;
	// var msgFrom = { _id: activeConversation.chat_id, name: activeConversation.userName, avatar: activeConversation.avatar };
	const dispatch = useDispatch();

	const [client, setClient] = useState(null);



	useEffect(() => {
		if (activeConversation) {
			setmsgFrom({ _id: activeConversation.chat_id, name: activeConversation.userName, avatar: activeConversation.avatar });
			closeButton(false);
		}
		else{
			closeButton(true);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeConversation]);

	useEffect(() => {
		if (allowed_recipients && allowed_recipients.length>0) {
			getUserChatList(chat_ticket, origin_chat_id);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowed_recipients]);

	const refreshClicked=()=>{

		refreshAllowed();
	}

	useEffect(() => {
		// if(chat_ticket === null || access_token===null ){
			setOfflineUnreadApplied(false);
			setUnreadMessageFrom([]);
			setActiveConversation(null);
			if(client){
				client.close();
			}
			if(access_token && chat_ticket){
				refreshClicked()
			}
		// }
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [access_token, chat_ticket]);

	useEffect(() => {
		if(!offlineUnreadApplied){
			if ( completeChatList && completeChatList.length>0 ) {
				completeChatList.map(singleChat=>{
					if(singleChat.thread_code && !unreadMessageFrom.includes(i=>i.targetThread === singleChat.thread_code) && singleChat.unread>=0){
						updateUnread(singleChat);
					}
					return true;
				})
				setOfflineUnreadApplied(true);
			}
		}

		if(completeChatList && completeChatList.length>0){
			if(activeConversation && !activeConversation.thread_code){
				let activeObj = completeChatList.find(i=>i.chat_id === activeConversation.chat_id);
				if(activeObj){
					setActiveConversation(activeObj);
				}
			}
		}
	 

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [completeChatList]);


	useEffect(() => {
		if (chat_ticket) {
			setClient(new WebSocketClient('wss://'+host+'/ws/chat/?ticket=' + chat_ticket, [], {
				backoff: "fibonacci",
			}));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chat_ticket]);

	const resetExistingTicket=()=>{
		dispatch({
			type: ChatActionTypes.RESET_CHAT_TICKET,
		});
	 return new Promise(function(resolve, reject) {
    resolve(123); // immediately give the result: 123
  });
	}
	const reqTicketFromParent=()=>{
		setTicketRequested(true);
		resetExistingTicket()
		.then(res=>{
			generateChatTicketFunc();
			setTimeout(() => {
				setTicketRequested(false);
			}, 3000);
		})
	}
	useEffect(() => {
		if (client) {
			client.onopen = () => {
				//  console.log('WebSocketClient Connected', client);
			};
			client.onclose = (event) => {
				// console.log(" WebSocketClient clientclosed",event)
				if (!ticketRequested && event.code !==1000) {
					reqTicketFromParent();
				}
			};

			client.onmessage = (event) => {
				updateMessages(event);
			};

			// client.onerror = (event) => {
			// 	console.log('client.onerror error', event);
			// 	// updateMessages(event);
			// };

			// client.onreconnect = (event) => {
			// 	console.log('onreconnect', event);
			// 	// updateMessages(event);
			// };
		}
	});

	const updateParentUnread = (unread) => {
		let parentUnreadCount = 0;
		unread.forEach(element => {
			parentUnreadCount = parentUnreadCount + element.count;
		});

		window.parent.postMessage({ event_id: 'parentUnreadCount', parentUnreadCount: parentUnreadCount }, api.routeFe);
	}

	const updateUnread = (returnedData) => {
		let unread = unreadMessageFrom;
		let targetIndex = unread.findIndex(i => i.targetThread === returnedData.thread_code);

		let c = 0;
		if (targetIndex >= 0) {
			c = unread[targetIndex].count;
			let count_to_add = 1;
			if(returnedData.unread !== undefined){
				count_to_add = returnedData.unread;
			}
			if(count_to_add >0){
				unread[targetIndex].count = c + count_to_add;
			}

		}
		else {
			let count_to_add = 1;
			if(returnedData.unread !== undefined){
				count_to_add = returnedData.unread;
			}
			let count = c + count_to_add;
			if(count>0){
				unread.push({
					targetThread: returnedData.thread_code,
					count: count
				})
			}
		}
		setUnreadMessageFrom(unread);
		updateParentUnread(unread);
	}

	const alertSound = () => {
		window.parent.postMessage({ event_id: 'alertSound', alertSound: true }, api.routeFe);
	}

	const closeButton = (showClose) => {
		window.parent.postMessage({ event_id: 'showClose', showClose: showClose }, api.routeFe);
	}

	const isMediaType = (msgType, type) => {
		if (msgType === type) {
			return true
		}
		return false;
	}



	const updateMessages = (e) => {
		var returnedData = JSON.parse(e.data);
		//  console.log("returnedData",returnedData);
		if(returnedData.message_confirm){
			let message_confirm = returnedData.message_confirm;
			if(message_confirm === 'true'){
				let confirm_msg_list =  _.map(messages, _.clone);
				confirm_msg_list = confirm_msg_list.map(msg=>{
					if(msg._id === returnedData.message_id){
						let temp = msg;
						temp["message_status"]=returnedData.message_status;
						return temp;
					}
					else{
						return msg;
					}					
				})
				// console.log("confirm_msg_list",confirm_msg_list)
				if((activeConversation.thread_code && activeConversation.thread_code === returnedData.thread_code) || (activeConversation.chat_id === returnedData.target_user_chat_id)){
					setMessages(confirm_msg_list);
					setCurrentMsgList(confirm_msg_list);
				}

				let reduxChatObj = _.map(completeChatList, _.clone);
				let userChatIndex = reduxChatObj.findIndex(i => (i.thread_code && returnedData.thread_code && i.thread_code === returnedData.thread_code) || (i.chat_id && i.chat_id === returnedData.target_user_chat_id));
				if (userChatIndex > -1) {
					reduxChatObj[userChatIndex]["userChat"] = confirm_msg_list;
					let sortedChatList = sortChatListByDateTime(reduxChatObj);
					dispatch({
						type: ChatActionTypes.UPDATE_COMPLETE_CHAT_LIST,
						completeChatList: sortedChatList
					});
				}
			}
		}
		else{
			let indicator = isMediaType(returnedData.message_type, "indicator");

			if ((activeConversation === null || activeConversation.thread_code !== returnedData.thread_code) && !indicator) {
				updateUnread(returnedData);
				alertSound();
			}
			else {
				if (indicator) {
					scrollToEnd(listViewRef);
					if (typingTimeout) {
						clearTimeout(typingTimeout);
					}
					setActiveUserTyping(returnedData.thread_code);
					setTypingTimeout(setTimeout(() => {
						setActiveUserTyping(null);
					}, WAIT_INTERVAL));
					return;
				}
				else if (activeUserTyping) {
					setActiveUserTyping(null);
				}
				scrollToEnd(listViewRef);
			}
	
			let msgTime = new Date();
			let msgText = returnedData.message;
			// this is YOUR INFO
			let first_reply = false;
			let userChatIndex = completeChatList.findIndex(i => i.thread_code && i.thread_code === returnedData.thread_code);
			if (userChatIndex < 0) {
				if (returnedData.user_type === "support") {
					userChatIndex = completeChatList.findIndex(i => i.chat_id === "support");
				}
				else {
					userChatIndex = completeChatList.findIndex(i => i.chat_id && i.chat_id === returnedData.target_user_chat_id);
				}
				first_reply = true;
			}
			if (userChatIndex > -1) {
				let reduxChatObj = completeChatList;
				let prevMsgsList = reduxChatObj[userChatIndex].userChat;
				let avatar = returnedData.avatar? returnedData.avatar:defaultAvatar ;
				let user_id = returnedData.target_user_chat_id;
				if(returnedData.user_type === "support"){
					avatar = supportAvatar;
					user_id = "support_user"
				}
				let msg_user = { 
					_id: user_id, 
					name: returnedData.first_name + " " + returnedData.last_name, 
					avatar: avatar
				}				
				// if(returnedData.user_type === "support"){
				// 	msg_user["avatar"]=supportAvatar;
				// }
				// else if(!returnedData.avatar){					
				// 	msg_user["avatar"]=defaultAvatar;
				// }
				// if(!returnedData.avatar){
				// 	if(returnedData.user_type === "support"){
				// 		msg_user["avatar"]=supportAvatar;
				// 	}
				// 	else{
				// 		msg_user["avatar"]=defaultAvatar;
				// 	}
				// }	
				let newMsg = {
					_id: returnedData.message_id,
					text: msgText,
					createdAt: msgTime,
					user: msg_user,
					image: isMediaType(returnedData.message_type, "image") ? getMediaLink(returnedData.media) : null,
					file: isMediaType(returnedData.message_type, "file") ? getMediaLink(returnedData.media) : null
				};
				let msgl = GiftedChat.append(newMsg, prevMsgsList);
				prevMsgsList.push(newMsg);
				reduxChatObj[userChatIndex]["userChat"] = msgl;
				if (first_reply || (activeConversation && returnedData.user_type === "support" && activeConversation.chat_id==="support" && !activeConversation.thread_code)) {
					reduxChatObj[userChatIndex]["thread_code"] = returnedData.thread_code;
					setActiveConversation(reduxChatObj[userChatIndex]);
					let unread = unreadMessageFrom;
					unread = unread.filter(i=>i.targetThread !== returnedData.thread_code);
					setUnreadMessageFrom(unread);
				}
				let sortedChatList = sortChatListByDateTime(reduxChatObj);
				dispatch({
					type: ChatActionTypes.UPDATE_COMPLETE_CHAT_LIST,
					completeChatList: sortedChatList
				});
				// if (activeConversation && returnedData &&( (activeConversation.thread_code === returnedData.thread_code) || (activeConversation.chat_id === "support" && returnedData.user_type==="support"))) {
				// 	setMessages(msgl);
				// 	setCurrentMsgList(msgl);
				// }
				if(activeConversation && returnedData &&
					((activeConversation.chat_id === returnedData.target_user_chat_id) || 
					(activeConversation.thread_code && activeConversation.thread_code === returnedData.thread_code))) 
					{
					setMessages(msgl);
					setCurrentMsgList(msgl);
				}
				else {
					setDummy(!dummy)
				}
			
			}
			else{
				getUserChatList(chat_ticket, origin_chat_id);
			}
		
		}

	

		// setTimeout(() => {
		// 	console.log("CLOSING AFTER 5 SECONDS");
		// 	client.close();
		// }, 5000)
	}


	const setCurrentMsgList = (list) => {
		dispatch({
			type: ChatActionTypes.UPDATE_CURRENT_CHAT_LIST,
			currentChatList: list
		});
	}


	useEffect(() => {
		if (language === "ar") {
			document.documentElement.setAttribute('dir', 'rtl');
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	const singleChatSelected = (singleChat) => {
		setActiveConversation(singleChat);
		let unread = unreadMessageFrom;
		let activeChatIndex = unread.findIndex(i => i.targetThread === singleChat.thread_code);
		if (activeChatIndex > -1) {
			unread.splice(activeChatIndex, 1);
		}
		let currentTarget = completeChatList.find(i => (i.thread_code && singleChat.thread_code && i.thread_code === singleChat.thread_code) || i.chat_id === singleChat.chat_id);

		if (currentTarget && currentTarget.userChat) {
			setCurrentMsgList(currentTarget.userChat);
			setMessages(currentTarget.userChat);
		}
		else{
			setCurrentMsgList([]);
			setMessages([]);
		}
		setUnreadMessageFrom(unread);
		updateParentUnread(unread);
		setTimeout(() => {
			if(listViewRef){
				scrollToEnd(listViewRef);
			}	
		}, 500);
	}

	const getTime = (ctdt) => {
		let today = new Date();
		let createdAt = new Date(ctdt);
		let timeToShow = 0;
		if (createdAt) {
			let createdAtComp = new Date(ctdt) ;
			let todayComp = today;
			if (createdAtComp.setHours(0,0,0,0) < todayComp.setHours(0,0,0,0)) {
				timeToShow = createdAt.getDate() + "/" + (createdAt.getMonth() + 1) + "/" + createdAt.getFullYear();
			}
			else {
				let h = createdAt.getHours();
				let m = createdAt.getMinutes();
				let timeFormat = " AM";
				if (h > 12) {
					timeFormat = " PM";
					h = h-12;
				}
				if (h < 10) {
					h = "0" + h;
				}
				if (m < 10) {
					m = "0" + m;
				}

				timeToShow = h + ":" + m + " " + timeFormat
			}
		}
		return timeToShow;
	}


	const getChatList = (completeChatList) => {
		let list_of_chat = completeChatList.map((singleChat, index) => {
			let lastMsg = null;
			if (singleChat.userChat.length > 0) {
				lastMsg = singleChat.userChat[singleChat.userChat.length - 1];
			}
			return (
				<TouchableWithoutFeedback key={index}
					style={{ height: 75 }}
					onPress={() => { singleChatSelected(singleChat) }} >

				<View style={[style.conversationItem, { justifyContent: "space-between" }, index === completeChatList.length-1? style.lastConversationItem: {}  ]}>
						{singleChat.avatar ?
							<img style={{
								height: 55,
								width: 55,
								padding: 16,
								borderRadius: "50%",
								objectFit:'contain'
							}} alt={"..."} src={singleChat.avatar} /> : <div
								style={{
									height: 55,
									width:55,
									padding: 16,
									borderRadius: "50%",
									objectFit:'contain'
								}} />}
						<View style={[{ display: "flex", flex: 1, height: "100%", paddingVertical: 10,justifyContent: 'center', flexDirection: 'column' }]}>
							<Text style={[style.userName, { fontSize: 16, fontWeight:'bold', color:TextColor, direction:isRTL?'rtl':'ltr' },style.ellipsis]}>{singleChat.userName} </Text>
							{lastMsg ? <Text style={[style.chatText, { fontSize: 14, color: TextColor,direction:isRTL?'rtl':'ltr' }, style.ellipsis]}>{lastMsg.image?"[Image]":lastMsg.text}</Text> : null}
						</View>
						<View style={[{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', height: "100%", paddingVertical: 10 }, !lastMsg ? { justifyContent: 'center', flexDirection: 'column' } : { justifyContent: 'flex-start', flexDirection: 'column' }]}>
							{lastMsg ? <Text style={{ fontSize: 10, color: TextColor, textAlign: 'center', marginHorizontal: 10, marginBottom:10 }}>{getTime(lastMsg.createdAt)}</Text> : null}
							{unreadMessageFrom.find(i => i.targetThread === singleChat.thread_code) ?
								<View style={{ height: 20, width: 20, borderRadius: "50%", backgroundColor: PrimaryColor,  marginLeft: 16, marginRight: 16, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, }} >
									<Text style={{ fontSize: 11, display: 'flex', justifyContent: 'center', alignItems: 'center', color:BlackColor, }}>{unreadMessageFrom.find(i => i.targetThread === singleChat.thread_code).count}</Text>
								</View> : null}
						</View>
					</View>
				</TouchableWithoutFeedback>
			)
		})
		// if (completeChatList.length === 1 && completeChatList[0].chat_id === "support") {
		// 	list_of_chat.push(
		// 		<View style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', padding: 20 }} key="support">
		// 			<Text>You are not subscribed to any courses.  Subscribe to a course to be able to message the instructor.</Text>
		// 		</View>
		// 	);
		// }
		return list_of_chat;
	}

	const showLoader = () => {
		return (
			<View style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
			<ActivityIndicator size={23} color={SecondaryColor} />
			</View>
		)
	}
	const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
		const paddingToBottom = 20;
		return layoutMeasurement.height + contentOffset.y >=
			contentSize.height - paddingToBottom;
	};

	const chatBlockContent = () => {
		if (activeConversation) {
			return (
				<View style={user_type && user_type === "support"?{height:"88%"} :{ height: "90%" }}>
					<Conversation activeConversation={activeConversation} convo={activeConversation.chat_id} client={client}
						setMessages={setMessages} messages={messages} msgFrom={msgFrom} activeUserTyping={activeUserTyping} giftedChatRef={giftedChatRef} listViewRef={listViewRef} unreadMessageFrom={unreadMessageFrom} setUnreadMessageFrom={setUnreadMessageFrom} reqTicketFromParent={reqTicketFromParent}/>
				</View>
			)
		}
		else {
			if (completeChatList.length > 0) {
				return (
					<View style={user_type && user_type === "support"?{height:"88%"} :{ height: "90%" }}>
						{chat_loader ? showLoader() :
							<ScrollView 
							onScroll={({nativeEvent}) => {
								if (isCloseToBottom(nativeEvent)) {
									let next_page = null;
									if(pagination_obj && pagination_obj.next){
										next_page = pagination_obj.next;
										getUserChatList(chat_ticket, origin_chat_id,next_page );
									}
								}
							}}
							contentContainerStyle={{
							shadowColor: 'grey',
							shadowOffset: { width: 1, height: 3 },
							shadowOpacity:  0.1,
							shadowRadius: 2,
							}}
							scrollEventThrottle={400}>
							{getChatList(completeChatList)}
							</ScrollView>}
					</View>
				)
			}
			else {
				return <Text >  No users to chat </Text>
			}
		}
	}

	if (chat_ticket) {
		return (
			<View style={{ height: "100%" , position:'relative'}}>
				<View style={{display:'flex', position:'absolute',  top:0, left:0, width:'100%', zIndex:9}}>
					<Heading activeConversation={activeConversation} setActiveConversation={setActiveConversation} />
				</View>
				<View style={{marginTop:50, height: "100%" }}>
				{chatBlockContent()}
				</View>
			</View>
		)
	}
	else {
		return (
			<View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", flex: 1, height: "100%" }}>
				<ActivityIndicator size={23} color={SecondaryColor}/>
			</View>
		)
	}
}

// function ConversationItem(props) {
//     return (<View style={style.conversationItem}><Text>User</Text></View>)
// }
function Heading(props) {
	let title = 'Chat List';
	const language = useSelector(state => state.chatReducer.chat_language);
	const { activeConversation, setActiveConversation } = props;
	const user_type = useSelector(state => state.chatReducer.user_type);
	
	const display_email = user_type === "support" && activeConversation && activeConversation.email && !activeConversation.userName ? true : false;
	if (activeConversation) {
		title = activeConversation.userName;
	}

	const backPressed = () => {
		setActiveConversation(null);
	}

	const refreshClicked=()=>{
		refreshAllowed();
	}

	return (
		<View style={[style.chatHeader, display_email ? { height: 67 , font:'unset'} : {}]}>			
			{activeConversation ?
				<TouchableWithoutFeedback onPress={backPressed} >
					<View style={{ position: "absolute", left: language === "ar" ? 0 : 16, width: 15, right: language === "ar" ? 16 : 0 }}>
						<FontAwesomeIcon icon={language === "ar"?faArrowRight :faArrowLeft} size={15} color={TextColor}  />
					</View>
				</TouchableWithoutFeedback> : null}

			<View style={style.userData}>
				{/* {activeConversation && activeConversation.avatar && <img style={{
					height: 25,
					width: 25,
					padding: 10,
					borderRadius: "50%",
					objectFit:'contain'
				}} alt="..." src={activeConversation.avatar} />} */}
				<Text style={style.title}>{title} </Text>
			</View>
			{display_email ?
				<Text style={style.email}> {activeConversation.email} </Text>
				: null}
			{!activeConversation && user_type !== "support"?
			<div class="tooltip"> 
				<TouchableOpacity onPress={refreshClicked} >
					<View style={[{ position: "absolute",width: 15}, language === "ar"? {left:16} : {right:  16}]}>
						<FontAwesomeIcon icon={faSync } size={15} color={TextColor}  />
						<span class="tooltiptext">Click to refresh Instructor Chat List </span>
					</View>
				</TouchableOpacity> </div> : null}
		</View>
	)
}

const style = StyleSheet.create({
	conversationItem: {
		backgroundColor: '#fff',
		height: 75,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",	
		// borderBottomWidth:1,
		// borderBottomColor:'#d9d9d6'
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 0 },
		shadowOpacity:  0.2,
		shadowRadius: 2,
	},
	lastConversationItem:{

		borderBottomWidth:0,
	},
	chatHeader: {
		backgroundColor: WhiteColor,
		height: 50,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		// shadowColor: '#000',
		// shadowOffset: { width: 1, height: 2 },
		// shadowOpacity:  0.2,
		// shadowRadius: 3,
		// elevation: 5,
		shadowColor: 'grey',
		shadowOffset: { width: 1, height: 3 },
		shadowOpacity:  0.2,
		shadowRadius: 2,
	},
	title: {
		color: TextColor ,
		fontSize: 18,
		fontFamily: 'CeraPro-Bold',
	},
	back: {
		position: "absolute",
		left: 16,
		color: WhiteColor,
	},
	userImg: {
		height: 30,
		width: 30,
		padding: 16
	},
	userData: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		height: 30
	},
	ellipsis: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		// display:"flex",
		// flexDirection:'row',
		// justifyContent:'flex-end'
	},
	email: {
		color: SecondaryColor
	},
	userName:{
		fontFamily: 'CeraPro-Bold',
	},
	chatText:{
		fontFamily: 'CeraPro-Medium',
	}
});