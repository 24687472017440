import React from 'react'
// import { GiftedChat, IMessage, User } from 'react-native-gifted-chat'
// import { View, Dimensions } from 'react-native'
import './App.css'
import ConversationList from './ConversationList/ConversationList'
import api from "./utils/api.js";


function App() {
 
	React.useEffect(() => {
    window.parent.postMessage({ event_id: 'iframeLoaded' }, api.routeFe);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
  return (
    <ConversationList />
  )
}

export default App
