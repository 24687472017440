import { store } from "./store.js";
import ChatActionTypes from "../constants/chatConstants";
import {
  getChatList,
  // getPrevMessages 
} from "../actions/chatActions";
import api from "../utils/api.js";
import { GiftedChat } from 'react-native-gifted-chat';
import { generateChatTicket , getBasketforGuest, verifyTokenFunc} from "../actions/chatActions.js";
// import supportAvatar from "../assets/img/route_logo.jpeg";
import _ from 'lodash';
export const supportAvatar = "https://api.routekw.com/site_media/static/chat_support_logo.png";
export const defaultAvatar = "https://api.routekw.com/site_media/static/default_user.png";


export const resetChatReducerCall = () => {
  store.dispatch({
    type: ChatActionTypes.RESET_CHAT_REDUCER,
  });
}
export const generateChatTicketFunc = (verifyTicket) => {
  let guest_ticket_fetch_count = store.getState().chatReducer.guest_ticket_fetch_count;
  generateChatTicket()
    .then(res => {
      let data = res.data;
      store.dispatch({
        type: ChatActionTypes.UPDATE_CHAT_TICKET_OBJ,
        chat_ticket_obj: data
      });

      let chat_data_obj = {
        chat_ticket: data.ticket,
        origin_chat_id: data.chat_id
      }
      if(verifyTicket){
        let verify = refreshAllowed();
      }
      updateReceivedValues(chat_data_obj, verifyTicket);
    })
    .catch(e => {
      console.log("ERRor", e);
      console.log("ERRor", e.response);
      if(e && e.response && e.response.status === 403 && guest_ticket_fetch_count < 3){
         guestCartAndTicket();
      }
    })
}

const guestCartAndTicket=()=>{
  let guest_ticket_fetch_count = store.getState().chatReducer.guest_ticket_fetch_count;
  getBasketforGuest()
  .then(res=>{
    store.dispatch({
      type: ChatActionTypes.UPDATE_BASKET,
      basket: res.data
    });
    store.dispatch({
      type: ChatActionTypes.UPDATE_GUEST_TICKET_FETCH,
      guest_ticket_fetch_count: guest_ticket_fetch_count+1
    });
    generateChatTicketFunc();
    
  })
  .catch(e=>{
    console.log("Error getting guest basket", e)
  })
}

export const updateReceivedValues = (data, verifyTicket) => {
  if (data.chat_ticket && verifyTicket!==true) {
    getUserChatList(data.chat_ticket, data.origin_chat_id)
  }
  // store.dispatch({
  //   type: ChatActionTypes.UPDATE_LANGUAGE,
  //   chat_language: data.language
  // });
  store.dispatch({
    type: ChatActionTypes.UPDATE_ORIGIN_CHAT_ID,
    origin_chat_id: data.origin_chat_id
  });
  store.dispatch({
    type: ChatActionTypes.UPDATE_CHAT_TICKET,
    chat_ticket: data.chat_ticket
  });
}

export const updateReceivedToken = (data) => {
  // if (data.chat_ticket) {
  //   getUserChatList(data.chat_ticket, data.origin_chat_id)
  // }

  store.dispatch({
    type: ChatActionTypes.UPDATE_ACCESS_TOKEN,
    access_token: data.access_token
  });
  setTimeout(() => {
    generateChatTicketFunc();
  }, 200);
  store.dispatch({
    type: ChatActionTypes.UPDATE_USER_TYPE,
    user_type: data.user_type
  });
  store.dispatch({
    type: ChatActionTypes.UPDATE_USER_AVATAR,
    user_avatar: data.avatar
  });
  store.dispatch({
    type: ChatActionTypes.UPDATE_LANGUAGE,
    chat_language: data.language
  });
  // store.dispatch({
  //   type: ChatActionTypes.UPDATE_ORIGIN_CHAT_ID,
  //   origin_chat_id: data.origin_chat_id
  // });
  // store.dispatch({
  //   type: ChatActionTypes.UPDATE_CHAT_TICKET,
  //   chat_ticket: data.chat_ticket
  // });

}


export const getTime = (timestamp) => {
  let dt = timestamp.split(" ");
  let d = dt[0].split("/");
  let t = dt[1].split(":");
  let created = new Date(d[2], parseInt(d[1]) - 1, parseInt(d[0]), t[0], t[1]);

  return created;
}

const getUserObj = (user_obj) => {
  let user = {};
  let user_avatar = defaultAvatar;
  if(user_obj.avatar){
    user_avatar = user_obj.avatar;
  }

  if(user_obj.user_type === 'support'){
    user_avatar = supportAvatar
  }

  if (user_obj) {
    user = { _id:user_obj.user_type === 'support'?"support_user":user_obj.user_id, name: user_obj.first_name? user_obj.first_name : "Guest" + user_obj.last_name?user_obj.last_name:"", avatar:user_avatar }
  }  
  return user;
}

const appendToGC = (newMsg, arr) => {
  return GiftedChat.append(newMsg, arr);
}

export const getMediaLink = (media) => {
  let url = api.getMedia() +  media;
  return url;
}

const createGiftedeMsg = (item) => {
  let time = getTime(item.timestamp);
  return ({
    _id: item.message_id && item.message_id!== "text"?item.message_id: getUniqueID(),
    // unique_message_id:generateUniqueMessageId(time),
    message_status: "OK",
    createdAt:time,
    image: item.message_type === "image" ?getMediaLink(item.media)  : null,
    user: getUserObj(item.user),
    text: item.message_type === "text" || item.message_type === "file" ? item.message : "",
    file: item.message_type === "file" ? getMediaLink(item.media) : null
  })
}

export const populateOldMsgs = (prev_chat) => {
  let arr = [];
  prev_chat.map(item => {
    let newmsg = createGiftedeMsg(item);
    arr = appendToGC(arr, newmsg)
    return true;
  })
  return arr;
}

const updateChatList = (origin_chat_id, chatThreads) => {
  let create_group_thread = true;
  let user_type = store.getState().chatReducer.user_type;
  let allowed_recipients = store.getState().chatReducer.allowed_recipients;
  let user_support = user_type === "support" ? true : false;
  let allowed_to_chat = true;
  let reduxChatObjNew = chatThreads.map(item => {
    let chat_id = "";   
    let prev_msgs = [];
    let prev_chat = [];
    if (item.chat_messages) {
      prev_chat = item.chat_messages;
    }
    if (prev_chat && prev_chat.length > 0) {
      prev_msgs = populateOldMsgs(prev_chat);
    }
    let user_name = user_support ? "Guest User" : "Instructor";
    let target = user_support ? item.origin_chat_user : item.target_chat_users[0];
    let avatar = defaultAvatar;

    if (target !== undefined) {
      if (target.first_name || target.last_name) {
        user_name = target.first_name + " " + target.last_name;
      }
      else if (target.email) {
        let em = target.email.split('@');

        user_name = em[0];
      }

      if (target.avatar) {
        avatar = target.avatar;
      }
      chat_id = target.user_id;
      allowed_to_chat = allowed_recipients? allowed_recipients.some(i=>i.chat_id === target.user_id):false;
    }
    else if (item.group === "support_group" && target === undefined && !user_support) {
      create_group_thread = false;
      user_name = "Support";
      avatar = supportAvatar;
      chat_id = "support";
    }

    return {
      userName: user_name,
      chat_id: chat_id,
      avatar: avatar,
      userChat: prev_msgs,
      thread_id: item.id,
      thread_code: item.code,
      unread:item.unread,
      email: target!==undefined && target.email ? target.email : null,
      allowed_to_chat:chat_id==='support'? true: allowed_to_chat
    }
  })
  if (create_group_thread && !user_support) {
    reduxChatObjNew.push({
      userName: "Support",
      chat_id: "support",
      avatar: supportAvatar,
      userChat: [],
      allowed_to_chat:true
    })
  }


  if (allowed_recipients.length > 0 && !user_support) {
    let startChatWith = allowed_recipients.filter(chat => {
      let foundInList = reduxChatObjNew.find(i => i.chat_id === chat.chat_id);
      if (!foundInList) {
        return chat;
      }
      return false;
    })
    startChatWith.map(item => {
      let username = "Instructor";
      if (item.first_name || item.last_name) {
        username = item.first_name + " " + item.last_name;
      }
      else if (item!==undefined && item.email) {
        let email = item.email.split('@');
        username = email[0];
      }


      reduxChatObjNew.push(
        {
          userName: username,
          chat_id: item.chat_id,
          avatar: item.avatar ? item.avatar : defaultAvatar,
          userChat: [],
          allowed_to_chat:true
        }
      )
      return true;
    })
  }

  // store.dispatch({
    //   type: ChatActionTypes.UPDATE_COMPLETE_CHAT_LIST,
    //   completeChatList: reduxChatObjNew
    // });
    saveToRedux(reduxChatObjNew,origin_chat_id);
    
    store.dispatch({
      type: ChatActionTypes.UPDATE_CHAT_LOADER,
      chat_loader: false
    });    
}


const saveToRedux=(reduxChatObjNew,origin_chat_id)=>{
  let prev_completeChatList = store.getState().chatReducer.completeChatList;
  // console.log("reduxChatObjNew", reduxChatObjNew);
  // console.log("prev_completeChatList", prev_completeChatList);

  let new_redux_obj = reduxChatObjNew.map(obj=>{
    let chat_obj = obj;
    let prev_obj = prev_completeChatList.find(i=>i.chat_id === chat_obj.chat_id);
    if(prev_obj){
      let prev_chat  = _.map(prev_obj.userChat, _.clone);
      let thread_chat  = _.map(chat_obj.userChat, _.clone);
      // console.log("prev_chat",prev_chat);
      // console.log("thread_chat",thread_chat);
      let unsent_messages = prev_chat.filter(e => {
        if(e.message_status!=="OK" && e.user && e.user._id === origin_chat_id){
          let inc = thread_chat.find(i=>i._id === e._id);        
          if(!inc){
            return e
          }
          return null;
        }
        return null;
      });
      // console.log("unsent_messages",unsent_messages); 
      if(unsent_messages && unsent_messages.length>0){
        let user_chat = chat_obj.userChat;
        unsent_messages = unsent_messages.map(i=>{
          let msg = i;
          msg["message_status"]='NOT_SENT';
          return msg;
        })
        user_chat.push(...unsent_messages);
        chat_obj["userChat"]=user_chat;
      }
      // console.log("chat_obj",chat_obj);
    }
    return chat_obj;  
  })
  
  let sortedChatList = sortChatListByDateTime(new_redux_obj);
  store.dispatch({
    type: ChatActionTypes.UPDATE_COMPLETE_CHAT_LIST,
    completeChatList: sortedChatList
  });

}

const saveSender = (data, origin_chat_id) => {
  let sender = data.find(i => i.origin_chat_user.user_id === origin_chat_id);
  if (sender) {
    store.dispatch({
      type: ChatActionTypes.UPDATE_SENDER_ID,
      sender_id: sender.origin_chat_user.id
    });
  }
}

const savePaginationDetails = (pagination_obj) => {
  if (pagination_obj) {
    store.dispatch({
      type: ChatActionTypes.UPDATE_PAGINATION_OBJ,
      pagination_obj
    });
  }
}

const saveCurrentURLFetch = (current_page_url) => {
  if (current_page_url) {
    store.dispatch({
      type: ChatActionTypes.UPDATE_CURRENT_PAGE_URL,
      current_page_url
    });
  }
}



export const getUserChatList = (chat_ticket, origin_chat_id, next_url ) => {
  let current_page_url = store.getState().chatReducer.current_page_url;
  if (chat_ticket) {
    let chat_list_url = api.root + 'chat/thread/list/' + chat_ticket + "/";
    if(next_url){
      chat_list_url = next_url;
    }

    if((current_page_url !== chat_list_url) || !next_url ){
    saveCurrentURLFetch(chat_list_url);
    getChatList(chat_list_url)
      .then(res => {
        // console.log("RESULT",res)
        if (res.data && res.data.results ) {
          let prev_data = store.getState().chatReducer.chat_list_obj;
          let new_chat_list_obj = res.data;
          let new_data = null;
          // let new_recipients = null;
          if(prev_data && prev_data.results && next_url){
            if(prev_data.results.data){
              new_data = prev_data.results.data.concat(new_chat_list_obj.results.data)
            }
            // if(prev_data.results.allowed_recipients){
            //   new_recipients = prev_data.results.allowed_recipients.concat(new_chat_list_obj.results.allowed_recipients)
            // }
            new_chat_list_obj["results"].data = new_data;
            // new_chat_list_obj["results"].allowed_recipients = new_recipients;
          }
          
          store.dispatch({
            type: ChatActionTypes.UPDATE_CHAT_LIST_OBJ,
            chat_list_obj:new_chat_list_obj
          });

          saveSender(res.data.results.data, origin_chat_id);

          let next = res.data.next;

            let paginationObj = {
              //pageNum:currentPage,
              next:next
            }

            savePaginationDetails(paginationObj);

          updateChatList( origin_chat_id, new_chat_list_obj.results.data);
        }
      })
      .catch(e => {
        console.log("Error", e)
        console.log("Error", e.response)
        store.dispatch({
          type: ChatActionTypes.UPDATE_CHAT_LOADER,
          chat_loader: false
        });
      })
    }
  }
}

export const getUniqueID = (props) => {
  // console.log("getUniqueIDprops",props);
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  return s4() + s4() + '-' + s4();
};

export const isUrl = (s) => {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return regexp.test(s);
}

export const isUrlWithVidTime = (s) => {
  let isurl = isUrl(s);
  let withTime = false;
  if (isurl) {
    var conditions = ["subscribe", "vid", "time"];
    withTime = conditions.every(el => s.includes(el));
  }
  return withTime;
}

export const messageHasURL = (msg) => {
  if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(msg)) {
    return true;
  }
  return false;
}

export const scrollToEnd=(listViewRef)=>{
  setTimeout(() => {
    if (listViewRef && listViewRef.current){
      listViewRef.current.scrollToEnd();
    }
  }, 200);
}


export const sortChatListByDateTime=(myArray)=>{
  let newList = _.orderBy(myArray,[
    function(thread) {
      let userChat =  _.map(thread.userChat, _.clone);
      if(userChat.length>0){
        let last_message = userChat[userChat.length-1];
        let date = last_message.createdAt;
        return new Date(date);
      }
      else{
        if(thread.chat_id==='support'){
          return new Date("2001-01-01");
        }
        else{
          return new Date("2010-01-01");
        }
        
      }
    }],["desc"]);
    return newList;
}
export const refreshAllowed = () => {
  let chat_ticket = store.getState().chatReducer.chat_ticket;
  let vertifyurl = api.verifyToken()+chat_ticket;
  verifyTokenFunc(vertifyurl)
  .then(res=>{
    let allowed_recipients = res.data.allowed_recipients;
    store.dispatch({
      type: ChatActionTypes.UPDATE_ALLOWED_RECIPIENTS,
      allowed_recipients
    });
  })
  .catch(e=>{
    console.log("E.res", e.response);
    if(e.response.status === 400 || e.response.status === 404){
      let verfifyAfterGenerate = true;
      generateChatTicketFunc(verfifyAfterGenerate);
    }
  })
  // return new Promise(function(resolve, reject) {
  //   // not taking our time to do the job
  //   resolve(123); // immediately give the result: 123
  // });
}