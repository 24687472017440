import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './chatStyle.scss';
import App from './App.js';
import * as serviceWorker from './serviceWorker';
// import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./utils/store";
import {updateReceivedToken, resetChatReducerCall} from "./utils/helpers.js";
import api from "./utils/api.js";

const { Provider } = require('react-redux');

window.addEventListener("message", function(event) {
  console.log("event.origin",event.origin);
  console.log(" api.routeFe", api.routeFe);
  if(event.origin === api.routeFe){
    if(event.data.resetChatRedux){
      resetChatReducerCall();
    }
    else{
      updateReceivedToken(event.data);
    }
    
  }
});


ReactDOM.render(
<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <App />
  </PersistGate>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
