import axios from "axios";
import { store } from "./store.js";

axios.defaults.withCredentials = true;


var PRODUCTION = true;
export var LOCAL = false;


const getTicket = () => {
  return store.getState().chatReducer.chat_ticket;
}




var PUBLIC_SITE="";
var CHAT_BACKEND="";
var ROUTE_BACKEND="";

if (PRODUCTION) {
  PUBLIC_SITE="https://routekw.com";
  CHAT_BACKEND="chat.routekw.com";
  ROUTE_BACKEND="https://api.routekw.com/api/v1/"

} else {
  PUBLIC_SITE="https://route.fe.hypertext.site";  
  CHAT_BACKEND="chat.route.hypertext.site";
  ROUTE_BACKEND="https://api.route.hypertext.site/api/v1/";
}

//LOCAL BACKEND
if(LOCAL){
  PUBLIC_SITE = "http://localhost:3000";
  // CHAT_BACKEND = "192.168.43.36:8000";
  // ROUTE_BACKEND="http://localhost:8080/api/v1/";
}


export const host = CHAT_BACKEND;

const api = {
  root: "https://" + host + "/",
  routeFe: PUBLIC_SITE,
  route_root: ROUTE_BACKEND,
  uploadMedia: () => {
    return api.root + 'chat/upload/file/' + getTicket() + "/";
  },
  getMedia: () => {
    return api.root + 'chat/file/request/validation/' + getTicket() + "/";
  },
  chatRoom: () => {
    return api.root + 'chat/?ticket=' + getTicket();
  },
  clearUnread: () => {
    return api.root + 'chat/thread/clear/' + getTicket()+"/";
  },
  generate_chat_ticket: () => {
    return api.route_root + "accounts/generate/chat/token/";
  },
  basketItems: () => {
    return api.route_root + "basket/"
  },
  verifyToken: () => {
    return api.route_root + "accounts/verify/chat/token/?ticket=";
  },
  // HEADERS
  headers: () => {
    let header_items = {
      "Content-Type": "application/json",      
      "Accept-Language": "en",
    };
    const access_token = store.getState().chatReducer.access_token;
    if (access_token !== null) {
      header_items["Authorization"] = "Bearer " + access_token;
    }
    return header_items;
  },

  // METHODS

  getFunction(url) {
    return axios({
      method: "get",
      url: url,
      headers: api.headers()
      // ,mode: 'cors',
      // credentials: true,cache: 'no-cache', 
    });
  },

  postFunction(url, data = {}, file = false, generateToken) {
    let headers = api.headers();
    if (file) {
      headers["Content-Type"] = "multipart/form-data";
    }
    if(generateToken){
      headers["app-origin"] = "web";
    }
    return axios({
      method: "post",
      url: url,
      headers: headers,
      data: data
    });
  },


  deleteFunction(url) {
    return axios({
      method: "delete",
      url: url,
      headers: api.headers()
    });
  },

  patchFunction(url, data = {}, file = false) {
    let headers = api.headers();
    if (file) {
      headers["Content-Type"] = "multipart/form-data";
    }
    return axios({
      method: "patch",
      url: url,
      headers: headers,
      data: data
    });
  },

};

export default api;
