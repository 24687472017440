import api from "../utils/api.js";

const getChatList = (url) => {
  return api.getFunction(url);
}

const getPrevMessages = (url) => {
  return api.getFunction(url);
}

const uploadMedia = (data) => {
  let url = api.uploadMedia();
  return api.postFunction(url, data, true);
}

const clearThreadUnread = (thread_id) => {
  let url = api.clearUnread()+thread_id+"/";
  return api.getFunction(url);
}

const loadEarlierMsgs = (user_token, thread_id, page) => {
  let url = api.root + "chat/messages/" + user_token + "/" + thread_id + "/?page=" + page;

  return api.getFunction(url);
}

const generateChatTicket = () => {
  let data={};
  let generateToken = true;
	return api.postFunction(api.generate_chat_ticket(), data ,{}, generateToken);
};

const getBasketforGuest = () => {
	return api.getFunction(api.basketItems());
};

const verifyTokenFunc = (vertifyurl) => {
	return api.getFunction(vertifyurl);
};


export {
  getChatList,
  uploadMedia,
  getPrevMessages,
  loadEarlierMsgs,
  clearThreadUnread,
  generateChatTicket,
  getBasketforGuest,
  verifyTokenFunc
}