import { createStore,
   applyMiddleware 
  } from "redux";
import { persistStore } from "redux-persist";
import logger from "redux-logger";
import rootReducer from "../reducers/rootReducer";
import {LOCAL} from "./api.js";

let store = createStore(rootReducer);

if(LOCAL){
  store = createStore(rootReducer,applyMiddleware(logger));
}




let persistor = persistStore(store);

export { store, persistor };