import React, { useState, useEffect, useRef } from 'react'
import {
  GiftedChat, Bubble, Send, Time,InputToolbar,Avatar
  //  InputToolbar 
} from 'react-native-gifted-chat';
import { View, Dimensions, Text, TouchableOpacity, StyleSheet, TextInput, Button, ActivityIndicator,  } from 'react-native';
import { useSelector, useDispatch } from "react-redux";
import ChatActionTypes from "../constants/chatConstants";
import { SecondaryColor, WhiteColor,  RightBubble, LeftBubble,TextColor, ThemeBlue } from "../universalVariables";
// This code generates unique message id.
import { getUniqueID, isUrlWithVidTime, messageHasURL, populateOldMsgs, scrollToEnd , getMediaLink, getUserChatList, supportAvatar, defaultAvatar, sortChatListByDateTime, updateReceivedValues , getTime} from "../utils/helpers.js";
import MessageImage from "./MessageImage.js";
// import DocumentPicker from 'react-native-document-picker';
import { uploadMedia, loadEarlierMsgs, generateChatTicket } from "../actions/chatActions";
// import LinearGradient from 'react-native-linear-gradient';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faImage, faFile, faPaperclip,faExclamationTriangle, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import downloadIcon from "../assets/svg/download-solid.svg";
import {clearThreadUnread} from "../actions/chatActions";
import _ from 'lodash';
// const { width, height } = Dimensions.get('window');

export function Conversation(props) {
  // const { width, height } = Dimensions.get('window');
  const [sendRef] = React.useState(React.createRef());

  const { activeConversation, setMessages, messages, activeUserTyping, listViewRef , setUnreadMessageFrom, unreadMessageFrom, reqTicketFromParent} = props;
  const language = useSelector(state => state.chatReducer.chat_language);
  const completeChatList = useSelector(state => state.chatReducer.completeChatList); //Complete chat list from redux
  const currentChatList = useSelector(state => state.chatReducer.currentChatList); //Current chat list from redux
  const origin_chat_id = useSelector(state => state.chatReducer.origin_chat_id);
  const user_type = useSelector(state => state.chatReducer.user_type);
  const user_avatar = useSelector(state => state.chatReducer.user_avatar);
  const chat_ticket_obj = useSelector(state => state.chatReducer.chat_ticket_obj);
  // const sender_id = useSelector(state => state.chatReducer.sender_id);
  const chat_ticket = useSelector(state => state.chatReducer.chat_ticket);
  const WAIT_INTERVAL = 2500;
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [videoLink, setVideoLink] = useState(null);
  const [isLoadingEarlier, setIsLoadingEarlier] = useState(false);
  const [loadPage, setLoadPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [resendClicked, setResendClicked] = useState("");
  // const [mediaUrl, setMediaUrl] = useState(null);
  const [allowedToSendIndicator, setAllowedToSendIndicator] = useState(true);

  // const [is_online, setIs_online] = useState(true);
  const isRTL = language === "ar" ? true : false;
  const { width, height } = Dimensions.get('window');
  let containerHt = height - 50;

  useEffect(() => {
    if (origin_chat_id) {
      let avatar = defaultAvatar;
      if(user_type && user_type==='support'){
        avatar = supportAvatar;
      }
      else if(user_avatar){
        avatar = user_avatar;
      }
      
      setUser({ _id:user_type && user_type==='support'?"support_user" : origin_chat_id, name: 'you', avatar:avatar})
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin_chat_id]);

  useEffect(() => {
 //clear unread
  if(activeConversation.thread_id && activeConversation.unread>0){
			clearThreadUnread(activeConversation.thread_id)
			.then(res=>{
        getUserChatList(chat_ticket, origin_chat_id); 
        let unread = unreadMessageFrom;
        if(unread.includes(i=>i.targetThread === activeConversation.thread_code)){
          unread = unread.filter(i=>i.targetThread !== activeConversation.thread_code);
          setUnreadMessageFrom(unread);
        }
			})
			.catch(e=>{
				console.log("error",e,e.response);
			})
  }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const setCurrentMsgList = (list) => {
    dispatch({
      type: ChatActionTypes.UPDATE_CURRENT_CHAT_LIST,
      currentChatList: list
    });
  }

  const typingIndicator = () => {
    return (
      <div className="container">
        <div className="typingIndicatorContainer">
          <div className="typingIndicatorBubble">
            <div className="typingIndicatorBubbleDot"></div>
            <div className="typingIndicatorBubbleDot"></div>
            <div className="typingIndicatorBubbleDot"></div>
          </div>
        </div>
      </div>
    )
  }

  const messagesRef = useRef(messages);
  messagesRef.current = messages;

  const fetchChatTicket=(messageData, unique_message_id, setUnsentTimeout)=>{
    generateChatTicket()
    .then(res => {
      let data = res.data;
      dispatch({
        type: ChatActionTypes.UPDATE_CHAT_TICKET_OBJ,
        chat_ticket_obj: data
      });

      let chat_data_obj = {
        chat_ticket: data.ticket,
        origin_chat_id: data.chat_id
      }
      updateReceivedValues(chat_data_obj);
      client.send(messageData);
      if(setUnsentTimeout){
        setTimeout(() => {           
          setMessages(messages=>change_status(messages,unique_message_id,activeConversation.thread_code));               
        }, 5000);
      }
    })
    .catch(e => {
      // fetchChatTicket(messageData, unique_message_id, setUnsentTimeout);
      console.log("ERRor", e);
      console.log("ERRor", e.response);
    })
  }

  const clientSendFunc=(messageData, unique_message_id, setUnsentTimeout)=>{
    let is_ticket_valid = checkTicket();
    if(is_ticket_valid){
      client.send(messageData);
      if(setUnsentTimeout){
        setTimeout(() => {           
          setMessages(messages=>change_status(messages,unique_message_id,activeConversation.thread_code));               
        }, 5000);
      }
    }
    else{
      fetchChatTicket(messageData, unique_message_id, setUnsentTimeout);
    }
  }

  const updateReduxWithMessages = ( newMsg, mediaUrl, mediaType) => {
    const { client, msgFrom } = props;
    if (client) {
      //set state with new message sent
      let new_msg = newMsg[0];
      let mergeMsgs = [...currentChatList, new_msg];
      new_msg["message_status"]="PENDING";
      // new_msg["unique_message_id"]=generateUniqueMessageId(new Date());
      let msgl = GiftedChat.append(new_msg, currentChatList);
      // console.log("msgl",msgl);
      setMessages(msgl);
    

      // sending data over websocket   
    
      let msgType = getMessageType(mediaType);
      let msg_atts = {
        'message': new_msg.text,
        'target_user_chat_id': msgFrom._id,
        'message_type': msgType,
        'media': mediaUrl,
        'thread_code': activeConversation.thread_code,
        'message_id':new_msg._id
      }
      if (msg_atts.target_user_chat_id === "support" && user_type !== "support") {
        msg_atts["support"] = true;
      }

      let messageData = JSON.stringify(msg_atts);
      let setUnsentTimeout = true;
      clientSendFunc(messageData,new_msg._id, setUnsentTimeout);

      //saving message in redux
      setCurrentMsgList(mergeMsgs);
      let reduxChatObj = [...completeChatList];
      // this should come from redux based on which convo.
      let userChatIndex = reduxChatObj.findIndex(i => i.chat_id && i.chat_id === activeConversation.chat_id);
      reduxChatObj[userChatIndex]["userChat"] = mergeMsgs;

      // console.log("reduxChatObj",reduxChatObj);
      let sortedChatList = sortChatListByDateTime(reduxChatObj);
      dispatch({
        type: ChatActionTypes.UPDATE_COMPLETE_CHAT_LIST,
        completeChatList: sortedChatList
      });
      scrollToEnd(listViewRef);
    }
    else {
      console.log("client is null")
    }
  }


  const change_status=(messages,message_id, thread_code)=>{
    let msg_list = _.map(messages, _.clone);
    let has_unsent = msg_list.find(i=>i._id ===message_id  && i.message_status!=="OK");
    if(has_unsent){
      let new_sent_list = msg_list.map(msg=>{
        if(msg._id === message_id && msg.message_status!=="OK"){
          let temp = msg;
          temp["message_status"]='NOT_SENT';
          // console.log("temp",temp);
          return temp;
        }
        else{
          return msg;
        }
      });
      
      setCurrentMsgList(new_sent_list);
      let new_gc_list = GiftedChat.append([], [...new_sent_list]);
  
      //update complete chat list used to keep the active conversation updated
      let userChatIndex = completeChatList.findIndex(i => (i.thread_code && i.thread_code === thread_code) || (i.chat_id && i.chat_id === activeConversation.chat_id));
      let reduxChatObj = _.map(completeChatList, _.clone);
      if (userChatIndex > -1) {
        reduxChatObj[userChatIndex]["userChat"] = new_gc_list;
        let sortedChatList = sortChatListByDateTime(reduxChatObj);
        dispatch({
          type: ChatActionTypes.UPDATE_COMPLETE_CHAT_LIST,
          completeChatList: sortedChatList
        });
      }
      return new_gc_list;
    }
    else{
      return messages;
    }
  }

	// useEffect(() => {
  //   window.addEventListener('online', handleNetworkChange);
  //   window.addEventListener('offline', handleNetworkChange);
  //   return function cleanup() {
  //     window.removeEventListener('online', handleNetworkChange);
  //     window.removeEventListener('offline', handleNetworkChange);
  //   };
	// },[]);

  // const handleNetworkChange =()=>{
  //   setIs_online(navigator.onLine);
  // }

  const getMessageType = (mediaType) => {
    let msgType = "text";
    if (mediaType) {
      msgType = mediaType;
    }
    return msgType;
  }

  const onSend = (newMsg, mediaUrl,mediaType) => updateReduxWithMessages(newMsg, mediaUrl, mediaType);

  const isObjectEmpty = (obj) => {
    if (Object.keys(obj).length === 0) {
      return true;
    }
    return false;
  }
  const renderMessageImage = (props) => {
    return (
      <MessageImage
        {...props}
        imageStyle={{
          width: width * 0.65,
          height: 150,
          borderRadius: 10,
        }}
      />
    );
  }

  const renderCustomViewBubble = (props) => {
    let isTimestampUrl = isUrlWithVidTime(props.currentMessage.text);
    let is_loader = props.currentMessage.file==="_show_loader_"? true: false;
    let isFile = !!props.currentMessage.file;
    if(is_loader){
      return (<View style={{ padding: 15, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height:150, width:220,}}>
       <ActivityIndicator size={30} color={SecondaryColor} /> 
      </View>)
    }
    else{
      
       if (isTimestampUrl) {
         return (
           <View style={{ padding: 15, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
             <FontAwesomeIcon icon={faPlay} size={45} color="white" />
           </View>
         )
       }
       else if (isFile) {
         return (
           <View style={{ padding: 15, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
             <FontAwesomeIcon icon={faFile} size={45} color={props.position === 'right'? SecondaryColor : WhiteColor} />
           </View>
         )
       }
    }
  }

  const bubbleClicked = (openExternal, image, file) => {
    if (openExternal) {
      let extLink = image;
      if (file) {
        extLink = file;
      }
      window.open(extLink, '_blank')
    }
  }

  const renderInputToolbar=(props)=>{
    return(
     <InputToolbar 
     {...props}
      primaryStyle={styles.inputToolbar}
      containerStyle={{borderWidth:0}}
      renderActions={(props) => renderActions(props, disabled)}
       />
    ) 
   }
  

  
   const renderBubble = (props) => {
    var prevBubbleUserId = isObjectEmpty(props.previousMessage) ? 0 : props.previousMessage.user._id;
    var currentBubbleUserId = isObjectEmpty(props.currentMessage) ? 0 : props.currentMessage.user._id;
    // const isLastBubble = isObjectEmpty(props.nextMessage);
    var prevBubbleDate = props.previousMessage.createdAt;
    var curBubbleDate = props.currentMessage.createdAt;
    prevBubbleDate = new Date(prevBubbleDate).setHours(0,0,0,0)
    curBubbleDate = new Date(curBubbleDate).setHours(0,0,0,0)
    const differentUser = (currentBubbleUserId !== prevBubbleUserId) || (prevBubbleDate && curBubbleDate && prevBubbleDate < curBubbleDate) ? true : false;

    const openExternal = props.currentMessage.image || props.currentMessage.file ? true : false;
    const onlyImage = props.currentMessage.image ? true : false;
    const hasUrl = messageHasURL(props.currentMessage.text);
    let isFile = !!props.currentMessage.file;

    return (
      <div className={(openExternal || hasUrl ? " messageImageContainer " : "  ") + (differentUser?( " firstBubbleContainer "+ (props.position === "right"?(isRTL? "RTLrightFirstBubble " : " rightFirstBubble " ):(isRTL?" RTLleftFirstBubble" :" leftFirstBubble "))): "")}
        onClick={() => bubbleClicked(openExternal, props.currentMessage.image, props.currentMessage.file)} >
        <Bubble
          {...props}
          wrapperStyle={{
            left: {
              backgroundColor:onlyImage?WhiteColor: LeftBubble,
              maxWidth: isFile? 150 : (width * 0.65),
              borderRadius: 10,
              borderTopLeftRadius:isRTL?7:0,
              borderTopRightRadius:isRTL?0:7,
              borderBottomLeftRadius:7,
              minWidth: isFile ? 150 : 80,
              marginTop:  3,
              shadowColor: '#000',
              shadowOffset: { width:isRTL? 2:2, height: 2 },
              shadowOpacity:  0.2,
              shadowRadius: 3,
              elevation: 2,
              marginLeft:isRTL?60:-36,
              marginRight:isRTL?-20:60,
              paddingLeft:onlyImage? 0: 15,
              paddingRight:onlyImage? 0:15,
              paddingTop:onlyImage?0:2,
            },
            right: {
              backgroundColor:onlyImage?WhiteColor: RightBubble,
              maxWidth: isFile? 150 : (width * 0.65),
              borderRadius: 10,
              minWidth: isFile ? 150 : 80,
              marginTop:  3,
              borderTopRightRadius:isRTL?7:0,
              borderTopLeftRadius:isRTL?0:7,
              borderBottomRightRadius:7,
              shadowColor: '#000',
              shadowOffset: { width: isRTL?-2:2, height: 2 },
              shadowOpacity:  0.2,
              shadowRadius: 3,
              elevation: 2,
              marginRight:isRTL?60:-36,
              marginLeft:isRTL?-20:60,
              paddingLeft:onlyImage?0:15,
              paddingRight:onlyImage?0:15,
              paddingTop:onlyImage?0:2,
            },
          }}
          textStyle={{
            left: {
              color: TextColor,
              marginLeft: 2,
              marginRight: 2,
              display: onlyImage ? "none" : "inline",
              fontFamily:'CeraPro-Bold',
              // paddingLeft:6,
              // paddingRight:6,
              // paddingTop:2
            },
            right: {
              color: TextColor,
              marginLeft: 2,
              marginRight: 2,
              display: onlyImage ? "none" : "inline",
              fontFamily:'CeraPro-Bold',
              // paddingLeft:6,
              // paddingRight:6,
              // paddingTop:2
            },
          }}
          renderCustomView={renderCustomViewBubble}
        />
        {openExternal || hasUrl ? <div className={" iconContainer " + (props.position === "left" ? " externalRight " : " externalLeft ")}>
          <img src={downloadIcon} alt="..." style={{
            fontSize: 15, width: 15,
          }} />
        </div> : null}
      </div>
    );
  }

  const renderSend = (props) => {
    return <Send {...props} ref={sendRef}  disabled={disabled} textStyle={{ color: SecondaryColor }} label={'Send'} />
  }

  const resendMessage=(unsentMessage)=>{
    setResendClicked(unsentMessage._id);
    const { msgFrom } = props;
    let msgType = "text";
    let mediaUrl = "";

    if(unsentMessage.image){
      msgType="image";
      mediaUrl=unsentMessage.image;
    }
    else if(unsentMessage.file){
      msgType="file";
      mediaUrl=unsentMessage.file;
    }

    let msg_atts = {
      'message': unsentMessage.text,
      'target_user_chat_id': msgFrom._id,
      'message_type': msgType,
      'media': mediaUrl,
      'thread_code': activeConversation.thread_code,
      'message_id':unsentMessage._id,
    }
    if (msg_atts.target_user_chat_id === "support" && user_type !== "support") {
      msg_atts["support"] = true;
    }
    let messageData = JSON.stringify(msg_atts);
    // client.send(messageData);
    let setUnsentTimeout = true;
    clientSendFunc(messageData,unsentMessage._id,setUnsentTimeout );
  }

  const isClosed = () => {
    const { client } = props;
    if ((client && client.readyState === 1)) {
      if(user_type === "support"){
        return false;
      }
      else if( user_type !== "support" && !activeConversation.allowed_to_chat){
        return true;
      }
    }
    else{
      return true;
    }    
  }
  const disabled = isClosed();
  const renderTime = (props) => {
    const onlyImage = props.currentMessage.image && props.currentMessage.text.length < 1 ? true : false;
    var onlyImageProps = {
      position: "absolute",
      top:-13,
      display:"flex"
    };
    if(props.position === "left"){
      onlyImageProps["right"]=-80;
      onlyImageProps["left"]='unset';
      if(isRTL){
        onlyImageProps["left"]=-80;
        onlyImageProps["right"]='unset';
      }
    }
    else{
      onlyImageProps["left"]=-80;
      onlyImageProps["right"]='unset';
      if(isRTL){
        onlyImageProps["right"]=-80;
        onlyImageProps["left"]='unset';
      }
    }
    // if (onlyImage) {
    //   onlyImageProps = {
    //     position: "absolute",
    //     right: 0,
    //     bottom: 0,
    //     display: 'flex',
    //     alignItems: 'flex-end',
    //     justifyContent: "flex-end",
    //     margin: 0,
    //     width: 200,
    //     height: 60,
    //     paddingBottom: 0,
    //   }
    // }

    return (
      <div style={onlyImageProps} >
        { props.currentMessage.message_status && props.currentMessage.message_status!=="OK"? 
          <View style={{display:"flex", flexDirection:'row', position:'absolute', left:-15, top:-3}}>         
            {props.currentMessage.message_status==="NOT_SENT" && resendClicked!==props.currentMessage._id && !disabled?
            <TouchableOpacity onPress={()=>resendMessage(props.currentMessage)}>
              <FontAwesomeIcon icon={faExclamationTriangle} size={15} color={SecondaryColor} />
            </TouchableOpacity>
            :<ActivityIndicator size={15} color={SecondaryColor}/>} 
          </View>
          :null}
        <Time
          {...props}
          timeTextStyle={{
            right: {
              color: TextColor,
              fontSize: 10,
              paddingTop: onlyImage ? 3 : 0
            },
            left: {
              color: TextColor,
              fontSize: 10,
              paddingTop: onlyImage ? 3 : 0,         
            }
          }}
          containerStyle={{
            width: "100%"
          }}
        />

      </div>
    )
  }

  const temporartMessageLoader=()=>{
    let msgTime = new Date();
    let temp_msg={
      _id: 'thisistempLoader',
      createdAt:msgTime,
      image:null,
      user: user,
      text: "",
      file: "_show_loader_"
    }
    let msgl_temp = GiftedChat.append(temp_msg, currentChatList);

    setMessages(msgl_temp);
  }
  const handleImageSelect = (e, onSend) => {
    const { msgFrom } = props;
    e.preventDefault();
    temporartMessageLoader();
    let file = e.target.files[0];
    // if (file === undefined) {
    //   setFileUrl(null);
    // }
    if (file) {
      let data = {
        file: file,
        sender: origin_chat_id,
        thread: activeConversation.thread_code,
      }
      if(activeConversation.chat_id !== 'support'){
        data["receiver"] = msgFrom._id
      }
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      uploadMedia(formData)
        .then(res => {
          let data = res.data;
          if (data.id) {
            var mediaType = "";
            let mediaUrl = data.id;

            if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg") {
              mediaType = "image";
            }
            else {
              mediaType = "file";
            }

            let msgArr = [];
            let msgTime = new Date();
            let msgObj = {
              _id: getUniqueID(),
              createdAt: msgTime,
              image: mediaType === "image" ? getMediaLink(mediaUrl) : null,
              user: user,
              message_status:"PENDING",
              // unique_message_id:generateUniqueMessageId(new Date()), 
              text: mediaType === "file" ? file.name : "",
              file: mediaType === "file" ? getMediaLink(mediaUrl) : null
            }
            msgArr.push(msgObj);
            onSend(msgArr, mediaUrl,mediaType);

            let fileInp = document.getElementById("fileInput");
            if (fileInp) {
              fileInp.value = "";
            }

          }
        })
        .catch(e => {
          console.log("responseJson ERROR", e, e.response)
        });

    }


  }

  const pickDocument = () => {
    let fileInp = document.getElementById("fileInput");
    if (fileInp) {
      fileInp.click();
    }
  }

  const renderActions = (props, disabled) => {
    return (
      <TouchableOpacity disabled={disabled} onPress={sheetToggle} style={{
        display: "flex", flexDirection: 'row',
        justifyContent: 'center', alignItems: 'center',
        height: 44, paddingHorizontal: 5,
      }}>
        <FontAwesomeIcon icon={faPaperclip} size={20} color={'grey'} />
      </TouchableOpacity>
    );
  };

  const onPressUrl = (props) => {
    window.open(props, '_newtab');
  }

  const sheetToggle = (action) => {
    let sheet = document.getElementById("sheet");
    let transBg = document.getElementById("transBg");
    if (sheet && action === "close") {
      sheet.classList.add("close");
    } else if (sheet && transBg) {
      sheet.classList.toggle("close");
      transBg.classList.toggle("d-none");
    }
  }

  const modalToggle = (action) => {
    let linkModal = document.getElementById("linkModal");
    let transBg = document.getElementById("transBg");
    if (linkModal && transBg) {
      if (action === "close") {
        linkModal.classList.add("close");
        transBg.classList.add("d-none");
      }
      else {
        linkModal.classList.toggle("close");
        transBg.classList.toggle("d-none");
      }
    }

    sheetToggle('close');
  }

  const allowedInputs = (att_type) => {
    let fileInp = document.getElementById("fileInput");
    if (fileInp) {
      if (att_type === "file") {
        fileInp.setAttribute("accept", ".txt,.pdf,.doc,.docx,.xsl,.xslx");
      }
      else if (att_type === "image") {
        fileInp.setAttribute("accept", ".jpg,.jpeg,.png")
      }
    }
  }

  const openGallery = (att_type) => {
    // setMediaType(att_type);
    allowedInputs(att_type);
    sheetToggle();
    pickDocument();
  }


  const renderSheetContents = () => {
    return (
      <View style={{
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'row'
      }}>
        <View style={styles.buttonTextContainer}>
          <TouchableOpacity style={styles.actionButtonContainer}
            onPress={() => openGallery("image")}
          >
            <FontAwesomeIcon icon={faImage} size={25} />
          </TouchableOpacity>
          <Text style={styles.label}>Image </Text>
        </View>
        <View style={styles.buttonTextContainer}>
          <TouchableOpacity style={styles.actionButtonContainer}
            onPress={() => openGallery("file")}
          >
            <FontAwesomeIcon icon={faFile} size={25} />
          </TouchableOpacity>
          <Text style={styles.label}>Document </Text>
        </View>
        {/* <View style={styles.buttonTextContainer}>
          <TouchableOpacity style={styles.actionButtonContainer} onPress={modalToggle}
          >
            <FontAwesomeIcon icon={faVideo} size={25} />
          </TouchableOpacity>
          <Text style={styles.label} >Timestamp</Text>
        </View> */}
      </View>
    )
  }

 
  const renderFooter = () => {
    if (activeUserTyping && activeUserTyping === activeConversation.thread_code) {
      return typingIndicator();
      // return <div/>
    }
    else {
      return <div />
    }
  }

  const sendTypinIndicator = () => {
    const { client, msgFrom } = props;
    if (client) {
      let msgType = "indicator";
      let msg_atts = {
        'message': '',
        'target_user_chat_id': msgFrom._id,
        'message_type': msgType,
        'thread_code': activeConversation.thread_code
      }
      if (msg_atts.target_user_chat_id === "support" && user_type !== "support") {
        msg_atts["support"] = true;
      }
      let messageData = JSON.stringify(msg_atts)
      // client.send(messageData);
      clientSendFunc(messageData);
    }
  }

  const checkTicket=()=>{
    if(chat_ticket_obj && chat_ticket_obj.expire_date){
      let ticket_expire_date =getTime(chat_ticket_obj.expire_date);
      let now = new Date();
      if(now < ticket_expire_date){
        return true;
      }
      else{
        return false;
      }
    }
  }

  const onInputTextChanged = (inputValue) => {
    if (inputValue.length > 0) {
      if (allowedToSendIndicator) {
        sendTypinIndicator();
        setAllowedToSendIndicator(false);
        setTimeout(() => {
          setAllowedToSendIndicator(true);
        }, WAIT_INTERVAL);
      }
    }
  }

  const onChangeText = (text) => {
    setVideoLink(text);
  }

  const sendLink = () => {
    // const { client, msgFrom } = props;
    let isTimestampUrl = isUrlWithVidTime(videoLink);
    if (isTimestampUrl) {
      let msgArr = [];
      let msgTime = new Date();
      let msgObj = {
        _id: getUniqueID(),
        createdAt: msgTime,
        user: user,
        text: videoLink,
        message_status:"PENDING",
        // unique_message_id:generateUniqueMessageId(new Date()), 
      }
      msgArr.push(msgObj);
      onSend(msgArr);
      modalToggle('close');
    }
    else {
      alert("Input is not a Video Timestamp URL ");
    }
  }

  const onKeyPress = (event) => {
    if (event.key === "Enter" && sendRef && sendRef.current) {
      sendRef.current.handleOnPress();
    }
  }


  const { client, giftedChatRef } = props;




  const onLoadEarlier = (props) => {
    setIsLoadingEarlier(true);
    loadEarlierMsgs(chat_ticket, activeConversation.thread_id, loadPage)
      .then(res => {
        if (res.data.next) {
          setLoadPage(loadPage + 1);
          setHasNextPage(true);
        }
        else {
          setHasNextPage(false);
        }
        let currentMsgs = [];
        if (loadPage > 1) {
          currentMsgs = messages;
        }
        let data = res.data;
        let prev_messages = populateOldMsgs(data.results);
        let newMsgs = [...prev_messages, ...currentMsgs];
        setMessages(newMsgs);
        // giftedChatRef.current._messageContainerRef.current.scrollToOffset({ animated: true, offset: 200 });
        setIsLoadingEarlier(false);
      })
      .catch(e => {
        setIsLoadingEarlier(false);
        reqTicketFromParent();
        console.log("onLoadEarlier error ", e.response);
      })
  }

  const showLoadEarlierButton = () => {
    if (activeConversation && activeConversation.thread_id && hasNextPage && messages.length > 2) {
      return true;
    }
  }


  const renderLoadEarlier = () => {
    return (
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {isLoadingEarlier ?
          <View style={{ padding: 10, margin: 10 }}>
            <ActivityIndicator size={23} color={SecondaryColor} />
          </View>
          : <TouchableOpacity style={{ backgroundColor: LeftBubble, borderRadius: 10, padding: 10, margin: 10 }} onPress={onLoadEarlier}>
            <Text style={ [styles.chatText, { color: TextColor , marginTop:2}]}> Load earlier messages </Text>
          </TouchableOpacity>}
      </View>
    )
  }



 
  const get_placeholder=()=>{
    let input_placeholder = "Type a message...";
    if(disabled){
      if(!activeConversation.allowed_to_chat){
        input_placeholder = "Please renew your subscription" 
      }
      else {
        input_placeholder = "Connecting..." 
      }
    }   
    return input_placeholder;
  }

  const renderAvatar=(props)=>{
    let style={ left: {marginLeft:10}, right:  {marginRight:10} };
    if(isRTL){
      style={left: {marginRight:20}, right:  {marginLeft:15}}
    }
    return(
        <Avatar
        {...props} 
        containerStyle={style}
        />
    )
  }
  return (
    <View style={{ flex: 1, height: containerHt, width: width, backgroundColor: 'transparent' }}>
      <input id="fileInput" accept=".jpg,.jpeg,.png," type="file" onChange={(e) => handleImageSelect(e, onSend)} style={{ opacity: 0, display: 'none' }} />

      <GiftedChat
        onLoadEarlier={onLoadEarlier}
        loadEarlier={showLoadEarlierButton() ? true : false}
        isLoadingEarlier={isLoadingEarlier}
        {...{ onSend, user }}
        ref={giftedChatRef}
        listViewProps={{
          ref: listViewRef
        }}
        renderAvatar={renderAvatar}
        // avatarStyle={{paddingLeft:50}}
        renderAvatarOnTop={true}
        showUserAvatar={true}
        renderLoadEarlier={renderLoadEarlier}
        // renderLoading={earlierMsgLoading}
        messages={messages}
        renderBubble={renderBubble}
        renderSend={renderSend}
        renderTime={renderTime}
        textInputProps={{ disabled: disabled, onKeyPress: onKeyPress }}
        placeholder={get_placeholder()}
        // alignTop={true}
        // renderMessage={renderMessage}
        renderMessageImage={renderMessageImage}
        inverted={false}
        // messageIdGenerator={()=>getUniqueID()}
        shouldUpdateMessage={(props, nextProps) => {
          return !_.isEqual(props.currentMessage.message_status,nextProps.currentMessage.message_status);
        }}
        alwaysShowSend={true}
        // renderMessageText={renderMessageText}
        // renderAccessory={renderAccessory}
        onInputTextChanged={onInputTextChanged}
        renderInputToolbar={renderInputToolbar}
        textInputStyle={{
          direction: isRTL ? "rtl" : "ltr",
          paddingLeft: 3,
          paddingRight: 10,
        }}
        // renderInputToolbar={renderInputToolbar}
        renderFooter={renderFooter}
        parsePatterns={() => [
          { type: 'url', style: { textDecorationLine: 'underline', color:SecondaryColor  }, onPress: onPressUrl },
        ]} 
        />
      {/* {typingIndicator()} */}
      <div className="translucentBg d-none" id="transBg" onClick={sheetToggle} />
      <div className="slider close" id="sheet">
        {renderSheetContents()}
      </div>

      <div className="linkModal close" id="linkModal">
        {/* <View style={styles.centeredView}> */}
        <View style={styles.modalView}>
          <TouchableOpacity style={{ position: 'absolute', right: 0, top: 0, padding: 10 }} onPress={() => modalToggle('close')}>
            <FontAwesomeIcon icon={faTimes} size={20} color={ThemeBlue} />
          </TouchableOpacity>
          <TextInput
            style={{ height: 40, borderColor: 'gray', borderWidth: 1, width: "100%", padding: 10 }}
            onChangeText={text => onChangeText(text)}
            placeholder={"Video Timestamp"}
          />
          <View style={{ marginTop: 20, width: 100 }}>
            <Button
              title="Send"
              color={ThemeBlue}
              onPress={sendLink}
            />
          </View>
        </View>
        {/* </View> */}
      </div>

    </View>
  )
}


const styles = StyleSheet.create({
  actionButtonContainer: {
    display: 'flex', alignItems: 'center', justifyContent: 'center',
    borderWidth: 2, borderColor: 'black',
    width: 50,
    height: 50,
    borderRadius: 50 / 2
  },
  buttonTextContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    // borderWidth:1, borderColor:'red'
  },
  label: {
    color: 'black',
    fontSize: 13,
    textAlign: 'center',
    marginTop: 5
  },
  // centeredView: {
  //   flex: 1,
  //   height: "100%",
  //   width: "100%",
  //   justifyContent: "center",
  //   alignItems: "center",
  // },
  modalView: {
    backgroundColor: "white",
    borderRadius: 5,
    padding: 35,
    alignItems: "center",
    width: "80%",
  },
  inputToolbar:{
    shadowRadius: 1,
    shadowOffset: {
      width: 0,
      height: -3,
    },
    shadowColor: '#000',
    elevation: 2,
    shadowOpacity:0.1
  },
  chatText:{
		fontFamily: 'CeraPro-Bold',
	}
});