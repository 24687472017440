import React from 'react';
import {View, Image, StyleSheet,  } from 'react-native';
// import ImageViewer from 'react-native-image-zoom-viewer';
//import FastImage from 'react-native-fast-image';
// import externalLink from "../assets/svg/external-link.svg";

export default class MessageImage extends React.PureComponent {
  render() {
    const { containerStyle, imageProps, imageStyle, currentMessage } = this.props;
    return (
      <React.Fragment>
        <div onClick={() => window.open(currentMessage.image, '_blank')} className="" >
          <View style={[styles.container, containerStyle]} >
            <Image
              {...imageProps}
              resizeMode={'cover'}
              style={imageStyle}
              source={{ uri: currentMessage.image }}
            />
          </View>
        </div>
      </React.Fragment>
    );
  }


}

const styles = StyleSheet.create({
  container: {

  },
  image: {

  },
  closeButton: {

  }
});