import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
// import { AsyncStorage } from 'react-native';
import ActionTypes from "../constants/chatConstants";
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';


const initialState = {
  completeChatList: [],
  currentChatList: [],
  chat_language: "en",
  origin_chat_id: null,
  chat_ticket: null,
  sender_id: null,
  chat_loader: true,
  user_type: null,
  pagination_obj: null,
  chat_list_obj:null,
  user_avatar:null,
  current_page_url:null,
  access_token:null,
  chat_ticket_obj: null,
  basket:null,
  guest_ticket_fetch_count:0,
  allowed_recipients:[]
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {

    case ActionTypes.UPDATE_COMPLETE_CHAT_LIST:
      return { ...state, completeChatList: action.completeChatList };

    case ActionTypes.UPDATE_CURRENT_CHAT_LIST:
      return { ...state, currentChatList: action.currentChatList };

    case ActionTypes.UPDATE_LANGUAGE:
      return { ...state, chat_language: action.chat_language };

    case ActionTypes.UPDATE_ORIGIN_CHAT_ID:
      return { ...state, origin_chat_id: action.origin_chat_id };

    case ActionTypes.UPDATE_CHAT_TICKET:
      return { ...state, chat_ticket: action.chat_ticket };

    case ActionTypes.UPDATE_SENDER_ID:
      return { ...state, sender_id: action.sender_id };

    case ActionTypes.UPDATE_CHAT_LOADER:
      return { ...state, chat_loader: action.chat_loader };

    case ActionTypes.UPDATE_USER_TYPE:
      return { ...state, user_type: action.user_type };

    case ActionTypes.UPDATE_PAGINATION_OBJ:
      return { ...state, pagination_obj: action.pagination_obj };

    case ActionTypes.UPDATE_USER_AVATAR:
      return { ...state, user_avatar: action.user_avatar };

    case ActionTypes.UPDATE_CHAT_LIST_OBJ:
      return { ...state, chat_list_obj: action.chat_list_obj };


    case ActionTypes.UPDATE_CURRENT_PAGE_URL:
      return { ...state, current_page_url: action.current_page_url };

    case ActionTypes.UPDATE_CHAT_TICKET_OBJ:
      return { ...state, chat_ticket_obj: action.chat_ticket_obj };

    case ActionTypes.RESET_CHAT_TICKET:
        return { ...state, chat_ticket_obj: null, chat_ticket:null };

    case ActionTypes.UPDATE_BASKET:
      return { ...state, basket: action.basket };

    case ActionTypes.UPDATE_ACCESS_TOKEN:
      return { ...state, access_token: action.access_token };

    case ActionTypes.UPDATE_ALLOWED_RECIPIENTS:
      return { ...state, allowed_recipients: action.allowed_recipients };

    case ActionTypes.UPDATE_GUEST_TICKET_FETCH:
      return { ...state, guest_ticket_fetch_count: action.guest_ticket_fetch_count };

    case ActionTypes.RESET_CHAT_REDUCER:
      return initialState;
    
      // case 'persist/REHYDRATE': 
      // return {...state, ...action.payload.dataReducer}

    default:
      return state;
  }
};

const persistConfig = {
  key: "chat",
  storage: storage,
  stateReconciler: hardSet,
  blacklist:["chat_language","guest_ticket_fetch_count"]
};

export default persistReducer(persistConfig, ChatReducer);