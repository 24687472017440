import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  RESET_CHAT_REDUCER: null,
  UPDATE_COMPLETE_CHAT_LIST: null,
  UPDATE_CURRENT_CHAT_LIST: null,
  UPDATE_LANGUAGE: null, 
  UPDATE_ORIGIN_CHAT_ID: null, 
  UPDATE_CHAT_TICKET: null,
  UPDATE_PAGINATION_OBJ: null,
  UPDATE_USER_AVATAR: null,
  UPDATE_CHAT_LOADER: null,
  UPDATE_SENDER_ID: null,
  UPDATE_USER_TYPE: null,
  UPDATE_CHAT_LIST_OBJ: null,
  UPDATE_ACCESS_TOKEN: null,
  UPDATE_ALLOWED_RECIPIENTS: null,
  RESET_CHAT_TICKET: null,
  UPDATE_CHAT_TICKET_OBJ: null,
  UPDATE_BASKET: null,
  UPDATE_GUEST_TICKET_FETCH: null,
  UPDATE_CURRENT_PAGE_URL: null,
  });
  
export default ActionTypes;